@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'tt trailers';
  src: url('/fonts/TTTrailers-Black.ttf');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('/fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('/fonts/Poppins-Bold.ttf');
}

@font-face {
  font-family: 'Goldman-Regular';
  src: url('/fonts/Goldman-Regular.ttf');
}

.font-goldman-regular {
    font-family: 'Goldman-Regular';
}

@font-face {
  font-family: 'Goldman-Bold';
  src: url('/fonts/Goldman-Bold.ttf');
}

.font-goldman-bold {
    font-family: 'Goldman-Bold';
}

@font-face {
  font-family: 'Jost-Regular';
  src: url('/fonts/Jost-Regular.ttf');
}

.font-jost-regular {
    font-family: 'Jost-Regular';
}

@font-face {
  font-family: 'Jost-Medium';
  src: url('/fonts/Jost-Medium.ttf');
}

.font-jost-medium {
    font-family: 'Jost-Medium';
}

@font-face {
  font-family: 'Jost-Light';
  src: url('/fonts/Jost-Light.ttf');
}

.font-jost-light {
    font-family: 'Jost-Light';
}

@font-face {
  font-family: 'Jost-SemiBold';
  src: url('/fonts/Jost-SemiBold.ttf');
}

.font-jost-semi-bold {
    font-family: 'Jost-SemiBold';
}

@font-face {
  font-family: 'Jost-Bold';
  src: url('/fonts/Jost-Bold.ttf');
}

.font-jost-bold {
    font-family: 'Jost-Bold';
}

@font-face {
  font-family: 'MonumentExtended-Regular';
  src: url('/fonts/MonumentExtended-Regular.otf');
}

.font-monument-regular {
    font-family: 'MonumentExtended-Regular';
}

@font-face {
  font-family: 'MonumentExtended-Ultrabold';
  src: url('/fonts/MonumentExtended-Ultrabold.otf');
}

.font-monument-bold {
    font-family: 'MonumentExtended-Ultrabold';
}

.task-number {
    font-family: 'Goldman-Bold';
}

@media (max-width: 767px) {
    .task-number {
        font-family: 'Jost-SemiBold';
    }
}

.button-task:disabled {
    opacity: 0.6;
}